<style lang="scss" scoped>
@import "@assets/styles/variables.scss"; //公共样式变量
</style>

<template>
  <!-- 部门管理 -->
  <section class="page-container">
    <div class="table-box">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="部门名称">
          <el-input v-model="searchForm.name" placeholder="部门名称"></el-input>
        </el-form-item>
        <el-form-item label="工单类型">
          <el-select v-model="searchForm.work_order_id" placeholder="工单类型">
            <el-option label="全部" value="" />
            <el-option
              v-for="item in workOrderList"
              :key="item.id"
              :label="item.name + `【${item.desc==null?'':item.desc}】`"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch" icon="el-icon-search">
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 搜索表单 -->
      <div class="table-container" id="tableContainer">
        <!-- 内页工具栏 -->
        <div class="tool-bar">
          <div class="tool-left">
            <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              v-if="smallRoleObj.add"
              @click="$router.push({ name: 'BRANCH_ADD' })"
            >
              添加
            </el-button>
          </div>
          <div class="tool-right">
            <el-tooltip effect="dark" content="刷新">
              <el-button
                size="small"
                icon="el-icon-refresh-right"
                @click="handleRefresh"
              />
            </el-tooltip>
            <el-tooltip effect="dark" content="全屏">
              <el-button size="small" @click="handleFullScreen">
                <span class="iconfont">
                  {{ (isFull && "&#xe641;") || "&#xe8fa;" }}
                </span>
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="table-data no-page" ref="tableContainer">
          <!-- 内页表格数据 -->
          <el-table
            v-loading="tableLoading"
            ref="tableBox"
            border
            style="width: 100%"
            row-key="id"
            default-expand-all
            :max-height="tableHeight"
            :data="tableData"
            :tree-props="treeProps"
          >
            <!-- <el-table-column type="index" width="50" align="center" label="序号" /> -->

            <!-- 改变箭头所在列，把想要设置展开行的列的前面的所有没有type的设置一个type=""-->
            <el-table-column type="" prop="id" width="50" align="center" label="ID" />
            <el-table-column prop="name" label="部门名称" min-width="100" align="left" />
            <el-table-column prop="desc" label="描述" min-width="100" align="left" />
            <el-table-column
              prop="work_order_name"
              label="工单类型"
              min-width="180"
              align="left"
            />
            <el-table-column prop="sort" label="排序" width="50" align="center" />
            <el-table-column
              prop="do_member_name"
              label="操作人"
              width="100"
              align="center"
            />
            <el-table-column label="创建时间" width="210" align="center">
              <template slot-scope="scope">
                <p>
                  {{ (scope.row.create_time * 1000) | formatTime("YYYY-MM-DD HH:mm:ss") }}
                </p>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="240" align="center" fixed="right">
              <template slot-scope="scope">
                <!-- <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-plus"
                  @click="handleShowDialog(scope.row.id)"
                >
                  添加子级
                </el-button> -->
                <el-button
                  class="color-primary"
                  type="text"
                  icon="el-icon-edit"
                  v-if="smallRoleObj.update"
                  @click="
                    $router.push({
                      name: 'BRANCH_EDIT',
                      params: { id: scope.row.id },
                    })
                  "
                >
                  编辑
                </el-button>
                <el-button
                  class="color-danger"
                  type="text"
                  icon="el-icon-delete"
                  v-if="smallRoleObj.delete"
                  @click="handleDelete(scope.row.id)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ueditorcom from "@/components/ueditorcom";
import { getWorkOrderList } from "@api/workOrder";
import { getBranchList, branchUpdate, branchAdd, branchDelete } from "@api/branch";
export default {
  name: "branchList",
  inject: ["reload"],
  /**
   * 组件
   */
  components: {
    ueditorcom,
  },
  data() {
    return {
      tableLoading: false,
      tableHeight: 0, //表格最大高度
      searchForm: {}, //搜索表单
      isFull: false, //全屏开启
      tableData: [], //表格数据
      workOrderList: [],
      treeProps: { children: "_child", hasChildren: "hasChildren" }, //树型表格配置项
      id: null, //修改部门id
      formDialogVisible: false, //修改/添加表单弹窗显示
      smallRoleObj: {},
      formData: {
        sort: 200,
      }, //表单存储数据
      rules: {
        name: [{ required: true, message: "请输入部门名称", trigger: "blur" }],
        sort: [{ required: true, message: "请选择部门排序", trigger: "change" }],
      }, //表单校验规则
    };
  },
  computed: {
    ...mapState("system", ["branchListSearch", "branchTreeList"]),
  },
  created() {
    // 获取初始化数据
    this.getData();
    /**
     * 页面dom元素加载完后调用，计算表格最大高度
     */
    this.$nextTick(function () {
      this.initTableHeight();
    });
  },
  async mounted() {
    window.addEventListener("resize", () => {
      // 窗口尺寸改变时触发事件
      if (!this.$screenfull.isFullscreen) {
        this.isFull = false;
      }
      this.initTableHeight();
    });
    this.smallRoleObj = await this.validateSmallRole();
    let res = await getWorkOrderList();
    this.workOrderList = res.data;
    this.$forceUpdate();
  },
  methods: {
    ...mapActions("system", ["getBranchList"]),
    /** 获取表格数据 */
    async getData() {
      this.tableLoading=true;
      /**
       * 请求接口数据
       */
      // console.log('this.searchForm',this.searchForm)
      // await this.getBranchList(this.searchForm);
      let res = await getBranchList(this.searchForm);
      this.tableData =res.data;// this.branchTreeList;
      this.$forceUpdate();
      this.tableLoading=false;
    },
    /** 查询 */
    handleSearch() {
      this.getData();
    },
    /** 刷新页面 */
    handleRefresh() {
      this.reload();
    },
    /** 初始化表格高度 */
    initTableHeight() {
      if (this.$refs["tableContainer"]) {
        this.tableHeight = this.$refs["tableContainer"].offsetHeight;
      }
    },
    /** 全屏 */
    handleFullScreen() {
      const element = document.getElementById("tableContainer");
      this.isFull = !this.isFull;
      if (this.isFull) {
        this.$screenfull.request(element);
      } else {
        this.$screenfull.exit(element);
      }
    },
    handleShowDialog(id) {
      this.formData = {
        ...this.formData,
        pid: id,
      };
      this.formDialogVisible = true;
    },
    /** 修改前获取详情信息 */
    async getDetail(id) {
      this.id = id;
      /**
       * 请求接口获取详情数据(部门详情)
       */
      let res = await branchUpdate({ id: this.id }, "get");
      // 数据请求返回成功后打开弹窗
      this.formDialogVisible = true;
      this.$nextTick(() => {
        // 注意看这里
        this.formData = {
          ...this.formData,
          ...res.data,
        };
      });
      // 赋值渲染百度编辑器内容
      if (res.data.remarks) {
        this.$nextTick((_) => {
          this.$refs.editor1.setUeditorContent("<p>" + res.data.remarks + "</p>");
        });
      }
    },
    handleCancle() {
      this.formDialogVisible = false;
      this.formData = {
        sort: 200,
      };
      this.id = null;
      this.$refs["formData"].resetFields();
    },
    handleSubmit() {
      this.$refs["formData"].validate(async (valid) => {
        if (valid) {
          /**
           * 请求接口
           */
          let res;
          let params = {
            ...this.formData,
            remarks: this.$refs.editor1.getUeditorContent(), //富文本编辑框数据赋值
          };
          if (this.id) {
            // 存在id，执行编辑请求
            res = await branchUpdate(params, "post");
          } else {
            // 不存在id，执行添加请求
            res = await branchAdd(params);
          }
          // 请求后，不论是否成功，调用方法关闭弹窗
          this.handleCancle();
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            // 请求成功后，调用方法，更新页面数据
            this.getData();
            // 请求成功后，调用方法，更新部门数据
            this.getBranchList();
          }
        }
      });
    },
    /** 删除 */
    handleDelete(id) {
      /** 弹窗再次确认操作 */
      this.$confirm("此操作将永久删除该部门, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        /** 确认触发 */
        .then(async () => {
          /**
           * 请求接口删除数据
           */
          let res = await branchDelete(id);
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
          }

          this.getData();
        })
        /** 取消触发 */
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除操作",
          });
        });
    },
  },
};
</script>
